export class RouteParams {
  private route: string;
  constructor(route: string) {
    this.route = route;
  }

  getParamByKey(key: string) {
    const decodedUri = decodeURI(this.route);
    if (decodedUri) {
      const paramObj = JSON.parse(
        '{"' +
          decodedUri
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );
      return paramObj[key];
    }
    return '';
  }
}
