import * as React from 'react';
import { Helmet } from 'react-helmet';
import FxFooterInfo from '../components/footer-info';
import FxLayout from '../components/shared/layout/layout';
import { BillsService } from '../services/bills-service';
import { RouteParams } from '../services/route-params';

export interface IDownload {
  result: any[];
}

export default class Download extends React.Component<any, IDownload> {
  private sessionId: string | undefined;
  private billsService: BillsService;
  constructor(props: any) {
    super(props);
    this.billsService = new BillsService();

    this.state = {
      result: []
    };

    if (typeof window !== 'undefined') {
      this.sessionId =
        (window &&
          new RouteParams(window.location.search.substring(1)).getParamByKey(
            'sessionId'
          )) ||
        undefined;

      if (this.sessionId) {
        this.billsService.getDownloadLinks(this.sessionId).then(
          (res: any) => {
            this.setState({ result: res });
          },
          (err: any) => {
            this.setState({ result: err });
          }
        );
      }
    }
  }

  render() {
    const { footers } = this.props.pageContext;

    return (
      <FxLayout>
        <Helmet>
          <title>Download - Findex Employee Benefits</title>
          <meta name="Exciting benefits for Findex employees" />
        </Helmet>
        {this.state && this.state.result && (
          <div className="fx-l-download">
            <div className="fx-l-download-heading">
              <div className="fx-title--subheading fx-l-download-heading-title">
                Download attachments
              </div>
              <hr className="hr--primary" />
            </div>
            {this.state.result.length === 0 && (
              <div className="fx-l-download-heading">
                <div className="fx-title--blurb fx-l-download-heading-title">
                  No attachments found!
                </div>
              </div>
            )}
            <div className="fx-l-download-filelist">
              {this.state &&
                this.state.result.map((f: any, i: number) => (
                  <div className="fx-l-download-file" key={i}>
                    <div className="fx-l-download-file-pdf" />
                    <div className="fx-l-download-file-link">
                      <a className="fx-btn fx-btn-primary" href={f}>
                        Download
                        <i className="material-icons">cloud_download</i>
                      </a>
                    </div>
                  </div>
                ))}
            </div>
            <div className="fx-l-footer">
              {footers && footers.length && <FxFooterInfo data={footers} />}
            </div>
          </div>
        )}
      </FxLayout>
    );
  }
}
